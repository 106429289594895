.MainUpperDiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding-top: 5px; */
    padding: 27px 16px 16px 16px;
}
.MainUpperRightDiv{
  display: flex;
  gap: 15px;
  font-size: 16px;
  font-weight: 500;
  /* margin-right: 15px; */
}
.addTaskSteps{
    margin-left: 10px;
    /* margin-top: 4px;
    margin-bottom: 4px; */
    /* padding:6px;
    font-size: 14px;
    font-weight: 600;
    gap: 18px;
    display: flex;
    align-items: center;
    border-left: 2px solid transparent; */
}
/* .addTaskSteps:hover{
    background-color: #ECF1FF ;
} */
.addTaskSteps:hover{
    /* background-color: #3A5371 ; */
    background-color: #FFFFFF66 ;
    border-radius: 4px 0px 0px 4px;
}
.addTaskStepsActive{
  border-right: 2px solid #FFFFFF;
  margin-left: 22px;
  /* margin-top: 4px;
  margin-bottom: 4px; */
  /* background-color: #ECF1FF ; */
  /* padding:0 12px; */
  font-size: 14px;
  font-weight: 600;
  gap: 18px;
  /* display: flex; */
  align-items: center;
  /* border-left: 2px solid #2564CF; */
}
.groupLists{
  padding: 6px;
  padding-right: 0px;
  margin-left: 12px;
  border-right: 2px solid transparent;
  /* cursor: pointer; */
  /* border-left: 2px solid transparent; */
}
.groupListsActive{
  /* background-color: #ECF1FF; */
  background: #FFFFFF66;
border-right: 2px solid #FFFFFF;
border-radius: 4px 0px 0px 4px;
  padding: 6px;
  padding-right: 0px;
  margin-left: 12px;
  /* cursor: pointer; */
  /* border-left: 2px solid #2564CF; */
}
.groupLists:hover{
  /* background-color: rgb(58, 83, 113); */
  background-color: #FFFFFF66 ;
  border-radius: 4px 0px 0px 4px;
  
}
.groupListsDark:hover{
  background-color: rgb(58, 83, 113) !important;
  border-radius: 4px 0px 0px 4px;
  
}
.addTask{
    background-color: #fff;
    padding: 6px 16px;
    height: 48px;
    /* padding-left: 20px;
    padding-right: 20px; */
    border-radius: 5px;
    /* margin-top: 20px; */
    /* box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px; */
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.2),0px 1.6px 3.6px rgba(0, 0, 0, 0.2);
    font-size: 16px;
    font-weight: 600;
    font-family: 'ManRopeSemiBold';
    color: #fff;
    gap: 8px;
    display: flex;
    align-items: center;
    /* margin: 0 20px; */
    /* min-height: 52px; */
}
.addTaskInput{
    font-size: 14px;
    font-weight: 500;
    border: none;
    background-color: transparent;
    width: 100%;
    color: #fff;
}
.addTaskInputDark{
    font-size: 14px;
    font-weight: 500;
    border: none;
    background-color: transparent;
    width: 100%;
}
.addTaskInputDark:focus{
  outline: none;
}
.addTaskInputDark:focus-visible{
  outline: none;
}
.addTaskInput:focus{
    outline: none;
}
.addTaskInput:focus-visible{
    outline: none;
}
.addTaskInput::placeholder {
    color: #fff;
}
.addTaskInputDark::placeholder {
    color: #fff;
}
.addProjectInput{
    font-size: 14px;
    font-weight: 400;
    border: none;
    background-color: transparent;
    width: calc(100% - 100px);

    /* width: 100%; */
}
.addProjectInput:focus{
    outline: none;
}
.addProjectInput:focus-visible{
    outline: none;
}
.addProjectInput::placeholder {
    color: #646464;
}
.addProjectNewListInput{
    font-size: 13px;
    font-weight: 500;
    border: none;
    background-color: transparent;
    width: 100%;
}
.addProjectNewListInput:focus{
    outline: none;
}
.addProjectNewListInput:focus-visible{
    outline: none;
}
.addProjectNewListInput::placeholder {
    color: #646464;
}
.addProjectNewListInput1{
    font-size: 13px;
    font-weight: 500;
    border: none;
    background-color: transparent;
    width: 100%;
}
.addProjectNewListInput1::placeholder {
    color: #646464;
}
.addTaskDrop{
  background-color: #fff;
  padding: 7px;
  padding-left: 13.5px;
  padding-right: 14px;
  /* box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px; */
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin: 0 20px;
  gap: 5px;
}
.addBtn{
    /* width: max-content; */
    border: 1.5px solid lightgrey;
    /* padding: 2px 8px; */
    border-radius: 5px;
    font-size: 10px;
    font-weight: 500;
    cursor: pointer;
    width: 60px;
    z-index: 1;
    text-align: center;
}
.dropItemsDiv{
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
}
.dropBox{
  background-color: aliceblue;
}
.taskListForTaskNotes{
  gap: 18px;
    display: flex;
    align-items: center;
    cursor: default;
    /* background-color: #fff; */
    background: #3068C81A;
    box-shadow: 0px 0.3px 0.9px rgba(0,0,0,0.1), 0px 1.6px 3.6px rgba(0,0,0,0.1);
    margin-top: 2px;
    margin-bottom: 16px;
    border-radius: 8px;
    padding: 5px 16px;
    /* min-height: 200px; */
    /* max-height: 252px; */
    /* overflow: auto; */
    display: flex;
    align-items: center;

}

.textAreaInputForTaskNote::-webkit-scrollbar {
  width: 4px;
}

.textAreaInputForTaskNote::-webkit-scrollbar:hover {
  width: 4px;
  background-color: #E4E8F4;
}


.textAreaInputForTaskNote::-webkit-scrollbar-thumb {
  background-color: #3068C8;
  border-radius: 5px;
  margin: 10px;
}
.textAreaInputForTaskNote::-webkit-scrollbar-thumb:hover {
  background-color: #7B7F88;
  border-radius: 5px;
  margin: 2px;;
}
.textAreaInputForTaskNote:hover::-webkit-scrollbar-corner {
  width: 4px;
  background-color: #E4E8F4;
  padding: 4;
}







.taskList{
    gap: 18px;
    display: flex;
    align-items: center;
    cursor: default;
    background-color: #fff;
    /* box-shadow: 0px 0.3px 0.9px rgba(0,0,0,0.1), 0px 1.6px 3.6px rgba(0,0,0,0.1); */
    /* box-shadow: 0px 0.3px 0.9px rgba(0,0,0,0.1), 0px 1.6px 5.6px rgba(0,0,0,0.1); */
    box-shadow: 0px 4px 8px 0px #00000014;
    margin-top: 2px;
    border-radius: 4px;
    padding: 5px 16px;
    min-height: 52px;
    display: flex;
    align-items: center;

}
.taskList:hover{
    background-color: #ECF1FF ;
    gap: 18px;
    display: flex;
    align-items: center;
    /* cursor: pointer; */
    box-shadow: 0px 0.3px 0.9px rgba(0,0,0,0.1), 0px 1.6px 3.6px rgba(0,0,0,0.1);
    margin-top: 2px;
    border-radius: 4px;
    padding: 5px 16px;
    min-height: 52px;
    display: flex;
    align-items: center;
}
.taskListChangeHover{
  gap: 18px;
  display: flex;
  align-items: center;
  /* cursor: pointer; */
  background-color: #fff;
  box-shadow: 0px 0.3px 0.9px rgba(0,0,0,0.1), 0px 1.6px 3.6px rgba(0,0,0,0.1);
  margin-top: 2px;
  border-radius: 4px;
  padding: 5px 16px;
  min-height: 52px;
  display: flex;
  align-items: center;

}
.taskListChangeHover:hover{
    /* background-color: lightgrey !important; */
    gap: 18px;
    display: flex;
    align-items: center;
    /* cursor: pointer; */
    box-shadow: 0px 0.3px 0.9px rgba(0,0,0,0.1), 0px 1.6px 3.6px rgba(0,0,0,0.1);
    margin-top: 2px;
    border-radius: 4px;
    padding: 5px 16px;
    min-height: 52px;
    display: flex;
    align-items: center;
}
.taskLine{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.taskLine > img{
    cursor: pointer;
    border-radius: 50%;
    padding: 5px;
    width:32px;
    z-index: 999;
}
.taskLine > img:hover{
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    padding: 5px;
    width:32px;
    z-index: 999;
}
.taskLineImages{
  display: flex;
  align-items: center;
  gap: 6px;
}
.taskLineImages > img{
    cursor: pointer;
    border-radius: 5px;
    padding: 5px;
    width:32px;
}
.taskLineImages > img:hover{
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 5px;
    width:32px;
}
.taskTitleHead{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    font-size: 18px;
    font-weight: 700;
    font-family: 'ManRopeBold';
    margin-top: 5px;
    /* margin-bottom: 10px; */
    word-break: break-word; /* 09/05 */

}
.sideTaskBarBottomDiv{
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    /* bottom: 7%;
    position: absolute;
    gap:12.5vw; */
}
.taskStepTitleRow{
    /* display: flex; */
    font-size: 13.5px;
    font-weight: 500;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 22px;
padding: 6px 6px;
/* height: 40px; */
color:#fff;

    /* color:'#292827'; */
    /* justify-content: space-between; */
    /* width: 100%;
    gap:22px;
    align-items: center; */
    /* padding-left: 16px; */
    /* border-left: 2px solid transparent; */
    /* cursor: default; */
    /* cursor: pointer; */
}
.taskStepTitleRowForBorder{
    width: 100%;
    font-size: 13.5px;
    font-weight: 500;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
padding: 6px 12px;
color:#fff;
/* margin-left: 12px; */
}
.sidebarFirstDiv{
    /* height: 100vh; */
    height: 80vh;
    flex: 1;
    /* padding: 12px; */
    overflow: scroll;
    /* margin-right: 1px; */
}

.sidebarFirstDiv::-webkit-scrollbar {
  width: 4px;
}

.sidebarFirstDiv::-webkit-scrollbar:hover {
  width: 4px;
  background-color: #E4E8F4;
}


.sidebarFirstDiv::-webkit-scrollbar-thumb {
  background-color: #33417E;
  border-radius: 5px;
  margin: 10px;
}
.sidebarFirstDiv::-webkit-scrollbar-thumb:hover {
  background-color: #7B7F88;
  border-radius: 5px;
  margin: 2px;;
}
.sidebarFirstDiv:hover::-webkit-scrollbar-corner {
  width: 4px;
  background-color: #E4E8F4;
  padding: 4;
}


.dropItemDiv{
   height: 250px;
   width: 100%;
   border: 1px solid black;
   display: flex;
   justify-content: center;
   align-items: center;
}
.completedTaskDiv{
   /* border-bottom: 0.5px solid #898383; */
   border-bottom: 0.5px solid #fff;
   color:#fff;
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-top: 20px;
   font-size: 14px;
   font-weight: 600;
   padding: 10px 0px ;
   cursor: pointer;
}
.downArrow{
    transform: rotate(-90deg);
    /* width: 24px;
    height: 28px; */
    /* margin-right: 10px; */
}
.rightArrow{
    transform: rotate(0deg);
    /* width: 24px;
    height: 28px; */
    /* margin-right: 10px; */
}
.titleHeadMenu{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
}
.taskListDiv{
    margin-top: 15px;
    /* height: 77.5vh; */
    /* height: 98vh; */
    height: calc(100vh - 15px);
    /* height: 73vh; */
    overflow: scroll;
    padding: 0px 16px 10px 16px;
}

.taskListDiv::-webkit-scrollbar {
  width: 4px;
}

/* .taskListDiv::-webkit-scrollbar:hover {
  width: 8px;
  background-color: #E4E8F4;
} */


.taskListDiv::-webkit-scrollbar-thumb {
  background-color: #3068C8;
  
  border-radius: 5px;
  margin: 10px;
}
/* .taskListDiv::-webkit-scrollbar-thumb:hover {
  background-color: #7B7F88;
  border-radius: 5px;
  margin: 2px;;
} */
.taskListDiv:hover::-webkit-scrollbar-corner {
  width: 8px;
  background-color: #E4E8F4;
  padding: 4;
}


.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input[type='file'] {
    color: rgba(0, 0, 0, 0)
  }
  .custom-file-input{
 width: inherit;
 cursor: pointer;
  }
  .custom-file-input::before {
    content: '+ Add attachments';
    /* color: #000; */
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    /* border: 1px solid #999; */
    border-radius: 3px;
    /* padding: 5px 8px; */
    outline: none;
    white-space: nowrap;
    /* -webkit-user-select: none; */
    cursor: pointer;
    /* text-shadow: 1px 1px #fff; */
    font-weight: 600;
    font-size: 14px;
    font-family: 'ManRopeSemiBold';
  }
  .custom-file-input::after {
    content: '';
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    /* border: 1px solid #999; */
    border-radius: 3px;
    /* padding: 5px 8px; */
    outline: none;
    white-space: nowrap;
    /* -webkit-user-select: none; */
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
  }


  .custom-file-input-dark::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input-dark[type='file'] {
    color: rgba(0, 0, 0, 0)
  }
  .custom-file-input-dark{
 width: inherit;
 cursor: pointer;
  }
  .custom-file-input-dark::before {
    content: '+ Add attachments';
    /* color: #fff; */
    /* color:'#969696'; */
    display: inline-block;
    /* background: linear-gradient(top, #f9f9f9, #e3e3e3); */
    /* border: 1px solid #999; */
    border-radius: 3px;
    /* padding: 5px 8px; */
    outline: none;
    white-space: nowrap;
    /* -webkit-user-select: none; */
    cursor: pointer;
    /* text-shadow: 1px 1px #fff; */
    font-weight: 500;
    font-size: 14px;
  }
  .custom-file-input-dark::after {
    content: '';
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    /* border: 1px solid #999; */
    border-radius: 3px;
    /* padding: 5px 8px; */
    outline: none;
    white-space: nowrap;
    /* -webkit-user-select: none; */
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
  }

  /* .custom-file-input:hover::before {
    border-color: black;
  } */
  .fileNamesList{
    background-color: #FAF9F8;
    /* padding: 10px; */
    /* padding-left: 20px; */
    /* padding-right: 20px; */
    border-radius: 5px 0px 0px 5px;
    margin-top: 4px;
    /* box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px; */
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.05);
    font-size: 14px;
    font-weight: 600;
    /* gap: 18px; */
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .fileNamesListInnerDiv{
    padding: 10px 0px 10px 20px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    gap: 16px;
    width: 85%;
  }
  .duplicateList{
    background-color: #FAF9F8;
    /* padding: 10px; */
    /* padding-left: 20px; */
    /* padding-right: 20px; */
    border-radius: 5px 0px 0px 5px;
    margin-top: 4px;
    /* box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px; */
    box-shadow: 0px 0.3px 0.9px rgba(0,0,0,0.1), 0px 1.6px 5.6px rgba(0,0,0,0.1);
    font-size: 14px;
    font-weight: 600;
    /* gap: 18px; */
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .duplicateListInnerDiv{
    padding: 10px 0px 10px 20px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    gap: 16px;
    width: 85%;
  }
  .flieName{
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    font-size: 12px;
  }
  .textAreaInput{
    border: none;
    background: transparent;
    width: 100%;
    color: black;
  }
  .textAreaInput:focus{
   outline: none;
  }
  .textAreaInputForTaskNote{
    border: none;
    /* background: transparent; */
    width: 100%;
    /* color: black; */
    color: #172D46;
    font-size: 14px;
    font-weight: 600;
    font-family: 'ManRopeSemiBold';
    /* background-image: url('../../assets/backGroundImgForNotes.png'); */
    min-height: 167px;
    max-height: 167px;
    overflow: auto;
    /* background-position: -20px 3px; */
  }
  /* .textAreaInputForTaskNote::-webkit-scrollbar{
    display: none;
} */
  .addTaskInput::before {
    font-size: 17px !important;
}
  .textAreaInputForTaskNote:focus{
   outline: none;
  }
  .textAreaInputForTaskNote > a{
cursor: pointer;
  }
  .MainProfileDiv{
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0px 12px;
  }
  .userAvtarDiv{
    height: 38px;
    width: 38px;
    /* height: 30px; */
    /* border: 1px solid red; */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 6px; */
    color: white;
    background: #3068C8;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    
  }
  .userDetailDiv > div{
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  .userDetailDiv > div > div{
    min-width: 10%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  .userDetailDiv{
 width: 83%;
  }

  .MainSwitchProfileDiv{
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 15px;
    /* border-bottom: 1px solid lightgray; */
    padding: 15px 6px;
    cursor: default;


    border-style: solid;
background: linear-gradient(white, white) padding-box, linear-gradient(6.5deg, rgba(255, 255, 255, 0.6) 55%, rgba(48, 104, 200, 1))14% border-box;
border-radius: 8px;
border: 2px solid transparent;
border-width: 2px 2px 0px 0px !important;

  }
  .MainSwitchProfileDivDark{
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 15px;
    /* border-bottom: 1px solid lightgray; */
    padding: 15px 6px;
    cursor: default;


    border-style: solid;
background: linear-gradient(#223853, #223853) padding-box, linear-gradient(6.5deg, rgba(34, 56, 83, 0.6) 55%, rgba(48, 104, 200, 1))14% border-box;
border-radius: 8px;
border: 2px solid transparent;
border-width: 2px 2px 0px 0px !important;

  }
  .MainSwitchProfileDiv:hover{
   background-color: rgba(0, 0, 0, 0.04);
  }
  .closeSwitchDialogBtn{
    /* border: 1px solid darkgray; */
    border: 1px solid rgba(48, 104, 200, 1);
    border-radius: 6px;
    padding: 10px 20px;
    width: 125px !important;
    color: #3068C8;
    width: fit-content;
    font-size: 14px;
    cursor: pointer;
    background: #fff;
    font-family: 'ManRopeSemiBold';
  }
  .closeSwitchDialogBtn:hover{
    background-color: #3068C8;
    color: #fff;
  }
  .switchAvtarDiv{
    height: 38px;
    width: 38px;
    /* height: 30px; */
    /* border: 1px solid red; */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 6px; */
    color: white;
    background: #3068C8;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    
  }
  .swicthDetailDiv{
 width: 78%;
  }
  .userAvtarDiv2{
    height: 30px;
    width: 30px;
    /* height: 30px; */
    /* border: 1px solid red; */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 6px; */
    color: white;
    background: #3068C8;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    
  }

  .newListAndGroup{
    background: #FFFFFF4D;
border-radius: 4px;
display: flex;
justify-content: center;
align-items: center;
height: 36px;
width: 120px;
padding: 0px 10px;
cursor: pointer;
  }
  /* .newListAndGroup:hover{
 background-color: #ECF1FF;
  } */
  .newListAndGroup:hover{
 background-color: #94b0e0;
  }
  .editTaskName:focus{
outline: none;
  }
  .editTaskName > a{
cursor: pointer;
  }
  .settingModalDiv{
    min-width: 200px;
    max-width: 290px;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0.3px 2.5px, rgba(0, 0, 0, 0.19) 0px 1.6px 3.6px;
    /* height: 100px; */
    background: #fff;
    position: absolute;
    z-index:1;
    margin-left: -175px;
    margin-top: 5px;
  }

  /* 23/04/23 */
  .settingModalItems {
    /* border-bottom: 1px solid #E1DFDD; */
    /* padding: 12px 15px; */
    padding: 8px 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px !important;
    cursor: pointer;
    width: 100%;
}
  .notificationsBellDiv {
    /* border-bottom: 1px solid #E1DFDD; */
    /* padding: 12px 15px; */
    padding: 3px;
    display: flex;
    align-items: center;
    font-size: 14px !important;
    cursor: pointer;
    width: 100%;
}
.MoveTaskToDiv{
  padding: 10px 15px 10px 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px !important;
  cursor: pointer;
  width: 100%;
}
  .MoveTaskToDiv > img {
height: 16px;
width: 16px;
  }
  .settingModalItems > img {
height: 16px;
width: 16px;
  }
  .settingModalItems > div > img {
height: 16px;
width: 16px;
  }
  .notificationsBellDiv > img {
height: 18px;
width: 18px;
  }
  .notificationsBellDiv > div > img {
height: 18px;
width: 18px;
  }

  .MoveListToGroup{
    position: absolute;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px ;
    background-color: white;
    min-width: 200px;
    max-width: 200px;
    margin-left: 220px;
    border-radius: 4px;
    min-height: 180px;
  }
  .moveListItem:hover{
    background-color: rgba(0, 0, 0, 0.04);
  }
  .moveListItemActive{
    color: #5E97C3 !important;
    background-color: rgba(0, 0, 0, 0.04);
    display: flex;
    justify-content: space-between !important;
    align-items: center !important;
    width: 100% !important;
  }

  .groupTitleRow{
    color: #fff;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  .groupDownArrow{
    /* transform: rotate(270deg);
    width: 16px;
    height: 20px; */
    transform: rotate(90deg);
    width: 20px;
    height: 28px;
    position: absolute;
    left: 5px;
}
  .groupUpArrow{
    /* transform: rotate(90deg);
    width: 16px;
    height: 20px; */
    transform: rotate(0deg);
    width: 20px;
    height: 28px;
    position: absolute;
    left: 5px;
}
  .projectWithGroup{
    /* height: max-content;
    overflow: scroll; */
      overflow: scroll;
  /* padding: 0px 5px; */
}
  .projectWithGroup::-webkit-scrollbar{
    display: none;
}
.MainUpperRightDiv > div {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* padding: 5px; 23/04/23 */
  border-radius: 4px;
}
.shareDiv:hover {
  /* background-color: #ECF1FF; */
  background: rgba(48, 104, 200, 0.7);
}
.whiteDots:hover {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.threeDots {
  padding: 0 !important;
  margin: 0 !important;
  min-width: auto !important;
  /* opacity: 0.6; */
  /* border: 1px solid red; */
}
.threeDots:hover {
  /* background-color: rgba(0, 0, 0, 0.1) !important; */
  opacity: 2;
}
.shareDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* padding: 5px; 23/04/23 */
  border-radius: 8px;
  background-color: #3068C8;
  width: 40px;
  height: 40px;
}
.rightListDiv{
  position: absolute;
  width: 220px;
  background-color: #fff;
  border-radius: 5px;
  box-sizing: border-box;
  z-index: 999;
  /* box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px; */
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px !important;
}

.rightListDiv > ul {
  box-sizing: border-box;
  padding: 5px 0px;
  margin: 0;
  list-style: none;
}
.rightListDiv > ul > li {
  padding: 8px 10px;
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  font-weight: 400;
}
.rightListDiv > ul > li > img {
  width: 18px;
}
/* hover */
.rightListDiv > ul > li:hover {
  cursor: pointer;
  background-color: #f5f8fe;
}


/* 23/04/23 */
.settingButton{
  padding: 4px !important;
    margin: 0 !important;
    min-width: auto !important;
   
}
.aiButton{
  padding: 4px !important;
    margin: 0 !important;
    min-width: auto !important;

    display: flex;
    border-radius: 8px;
    background-color: rgb(48, 104, 200);
    width: 40px;
    height: 40px;
    color: white;
    font-size: 22px;
    font-weight: 900;
   
}
.settingButton:hover{
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.css-advny6-MuiPopper-root{
  min-width: 220px !important;
  max-width: 220px !important;
  margin-left: -20px !important;
}
.css-1ps6pg7-MuiPaper-root{
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px !important;
}
.closeIconDiv{
  position: absolute;
  right: 0;
  top: 0;
  padding: 0px 10px;
  z-index: 999;
  cursor: pointer;
  display: none;
}
.closeIconDiv:hover{
  /* background-color: #f5f8fe; */
  background-color: rgba(0, 0, 0, 0.1);
}
.closeIconDiv > img{
 width: 8px;
 height: 10px;
}
.sideBarCloseIconDiv{
  position: absolute;
  right: 18px;
  top: 16px;
  padding: 0px 10px;
  z-index: 999;
  cursor: pointer;
}
.sideBarCloseIconDiv:hover{
  /* background-color: #f5f8fe; */
  background-color: rgba(0, 0, 0, 0.1);
}
.sideBarCloseIconDiv > img{
 width: 8px;
 height: 10px;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root{
  padding: 4px !important;
}
.oneLine-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap; /* let the text wrap preserving spaces */
  margin-left:2px;
  word-break: break-all;
}
.oneLine-ellipsis-list-name {
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 1 !important; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap !important; /* let the text wrap preserving spaces */
  margin-left:2px;
}
.react-confirm-alert-body{
  display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* padding: 30px 30px 70px 30px !important; */
}
.dontCheckBox{
  width: 15px;
  height: 15px;
  border: 1px solid #7f7f7f;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.confirmAlertCheckBoxDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  /* position: absolute; */
    margin-top: 15px;
    font-size: 14px;
    color: gray;
}
/* .sideMenuUpperDiv{
  overflow: scroll;
  height: 93.5%;
  padding: 0px 5px;
} */
.sideMenuUpperDiv::-webkit-scrollbar{
  display: none;
}
.deleteConfirmAlert{
  background-color: red;
}


/* override alert dialog defaults */
.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.4) !important;
  z-index: 1500 !important;
}
.react-confirm-alert {
  font-family: Arial, Helvetica, sans-serif;
  width: 450px;
  /* padding: 30px; */
  text-align: left;
  /* background: #F3F3F3; */
  background: transparent;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
/* custom alert dialog styles */
.add-dialog{
  width: 450px;
  border-radius: 4px !important;
/* padding: 30px; */
/* background-color: #F3F3F3; */
}
.add-dialog-inner-div{
  padding: 24px 16px;
  font-size: 14px !important;
  font-family: 'ManRope' !important;
}
.add-dialog h3 {
  margin: 0;
}
.add-dialog-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap:20px;
}
.add-dialog-buttons > button{
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  margin-right: 0px !important;
  background-color: #5E97C3 ;
  font-weight: 600 !important;
}
.deleteTrashTaskBtn > button{
  font-size: 14px;
  margin-left: 8px;
  padding-top: 7px;
  padding-bottom: 7px;
  min-width: 114px;
  outline: none;
    background: #333;
    border: none;
    display: inline-block;
    border-radius: 5px;
    font-size: 12px;
}


@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 15px;
  height: 15px;
  /* border: 2.5px solid #f3f3f3;
  border-top: 2.5px solid #383636;
  border-left: 2.5px solid #383636 ;
  border-right: 2.5px solid #383636 ; */
  border-radius: 50%;
  animation: spinner 1.3s linear infinite;
}

.loading-auth-spinner {
  /* border: 2.5px solid #f3f3f3;
  border-top: 2.5px solid #383636;
  border-left: 2.5px solid #383636 ;
  border-right: 2.5px solid #383636 ; */
  border-radius: 50%;
  animation: spinner 1.3s linear infinite;
}
.authLoaderDiv{
  position: absolute;
  /* width: 100%; */
  min-height: 500px;
  width: 550px;
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255,255,255,0.8);
  border-radius: 15px;
  padding: 40px 10px;
}
.highlightColorDiv{
padding: 8px;
}
.mainColorDiv{
  display: grid;
  justify-content: center;
  align-items: center;
  grid-column: 4;
  grid-template-columns: 4;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 15px;
  margin-top: 15px;
}
.colorUpperDiv{
  display: flex;
  justify-content: center;
  align-items: center;
}
.colorDiv{
  width: 31px;
  height: 30px;
  border-radius: 5px;
  cursor: pointer;
}
.listTaskCountdiv{
  font-size: 10px;
  /* background: rgba(0, 0, 0, 0.1); */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px 5px;
  background: #D9D9D952;
font-family: 'ManRope';
}
.newListInputBorders{
  border-top: 0.5px solid lightgray;
  border-bottom: 0.5px solid lightgray;
  /* margin-top: 5px; */
  padding: 0px 6px;
}
.newListInputBordersTransparent{
  border-top: 0.5px solid white;
  border-bottom: 0.5px solid white;
  /* margin-top: 5px; */
  padding: 0px 6px;
}
.editTextTaskName{
  /* outline: -webkit-focus-ring-color auto 1px; */
  outline: none;
    font-size: 14px;
    font-weight: 400;
    font-family: 'ManRopeBold';
    border: none;
    padding: 0px 20px 0px 0px;
    background: transparent;
    margin-right: 4px;
    margin: 0px;
    word-wrap: break-word;
    word-break: break-word;
    /* padding: 2px 0px;
    padding-right: 20px; */
}
.editTextTaskHeadingName{
  /* outline: -webkit-focus-ring-color auto 1px; */
  outline: none;
    font-size: 16.4px;
    font-weight: 800;
    border: none;
    padding: 0px 20px 0px 0px;
    background: transparent;
    margin-right: 4px;
    margin: 0px;
    word-wrap: break-word;
    word-break: break-word;
    /* padding: 2px 0px;
    padding-right: 20px; */
}
.popperWidth{
  min-width: 220px !important;
  max-width: 220px !important;
}
.MuiMenu-paper{
  min-width: 220px !important;
  max-width: 220px !important;
}
.projectListInput{
  width: 100%;
  padding: 4px;
}
/* [data-rbd-drag-handle-context-id="0"] {
  transition: none  !important;
} */

[data-placeholder]:empty:before{
  content: attr(data-placeholder);
  color: #172D46;
  font-size: 14px;
  font-weight: 500;
}
.CodeMirror-code {
  outline: none;
}
.dueAndReminder{
  margin-bottom:4px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.05);
  /* box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 7px 0px, rgba(0, 0, 0, 0.15) 0px 0px 6px 1px !important; */
}
.dueAndReminder > div{
  gap: 18px;
  display: flex;
  align-items: center;
  padding: 10px 16px;
}
.addSubTask{
  margin-top: 3px;
  margin-bottom: 10px;
  background-color: #fff;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.05);
  /* box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 7px 0px, rgba(0, 0, 0, 0.15) 0px 0px 6px 1px !important; */
  display: flex;
  align-items: center;
}
.addSubTaskInput{
  font-size: 14px;
  font-weight: 500;
  border: none;
  background-color: transparent;
  width: 100%;
  margin-left: 8px;
}

.addSubTaskInput:focus{
  outline: none;
}
.addSubTaskInput:focus-visible{
  outline: none;
}
.addSubTaskInput::placeholder {
  color: #605E5C;
}
.addSubTaskDarkInput::placeholder {
  color: #fff !important;
}
.makeStyles-root-21{
  font-size: 12px !important  ;
}
.shareDialogMainDiv{
  padding: 20px;
  min-width: 600px;
  max-width: 600px;
  background-color: white;
}
.shareDialogHeading{
  display: flex;
  justify-content: space-between;
}
.shareDialogInputAndIcon{
  display: flex;
  justify-content: center;
  /* margin-top: 15px; */
  border: 1px solid rgba(16, 24, 40, 0.2);
  /* margin-top: 22px; */
  border-radius: 4px;
}

.SharePeopleIconDiv{
  padding: 5px;
      /* border: 1px solid black; */
      /* border-left: 1px solid rgb(118, 118, 118); */
      cursor: pointer;
}
.shareDialogInput{
  width: 100%;
padding: 4.5px;
border: none;
background: transparent;
}
.shareDialogInput:focus-visible{
 outline: none;
}
.ShareDialogImgDiv{
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  font-size: 14px;
  color: grey;
}
.sharedialogFooter{
  border-top: 1px solid lightgray;
  padding: 10px 20px ;
  display: flex;
  align-items: center;
}
.sharedialogFooter > div{
transform: scale(0.6);
}
.switch-control {
  margin: 0 auto;
  width: 200px;
}
.innerDiv{
  padding-bottom: 14px;
  display: flex;
  align-items: center;
  position: relative;
}
.deleteTextDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}
[data-rbd-drag-handle-context-id="0"]{
  cursor: default !important;
}
[role=button]{
  cursor: pointer !important;
}
.css-gvoll6{
  align-items: center;
}
.css-gvoll6 > p{
font-size: 14px;
}
.css-i4bv87-MuiSvgIcon-root{
  font-size: 18px;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
  width: 100% !important;
  max-width: 100% !important;
  height: 100vh !important;
  background-color: transparent !important;
  box-shadow: none !important;
  justify-content: center !important;
  align-items: center !important;
  overflow: hidden !important;
  }
  .css-uhb5lp{
  width: 100% !important;
  max-width: 100% !important;
  height: 100vh !important;
  background-color: transparent !important;
  box-shadow: none !important;
  justify-content: center !important;
  align-items: center !important;
  overflow: hidden !important;
  }
  .css-hz1bth-MuiDialog-container{
    background: rgba(0,0,0,0.7) !important;
  }
  .css-ekeie0{
    background: rgba(0,0,0,0.7) !important;
  }
.pan-container > div{
  display: flex;
  justify-content: center !important;
align-items: center !important;
}
/* #basic-mui-menu{
  position: absolute !important;
  right: inherit !important;
}
#basic-mui-menu > div{
  position: inherit !important;
} */

.completeTag{
  display: flex;
  padding: 12px 12px 12px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
background: #3068C8;
color: #FFFFFF;
font-size: 16px;
font-weight: 600;
font-family: 'ManRopeSemiBold';
}
.subTaskMainDiv{
  display: flex;
  flex-direction: column;
  background-color: #fff;
  /* box-shadow: 0px 0.3px 1.9px rgba(0,0,0,0.1), 0px 0.6px 5.6px rgba(0,0,0,0.1); */
}

.auto-grow-input:focus-within {
  outline: -webkit-focus-ring-color auto 1px;
  background: transparent !important;
}

.auto-grow-input::-webkit-scrollbar{
  display: none;
}

.commentArea{
  outline: 0;
  width: auto;
  min-width: 1em;
  font-size: 14px;
  padding: 0;
  margin: 0;
  resize: none;
  background: none;
  appearance: none;
  border: none;
}


.MainCommentDiv{
  margin-bottom: 8px;
  margin-top: 8px;
  display: flex;
  /* align-items: center; */
  gap: 12px;
  /* padding-left: 10px; */
}
.userNameLetterCommentDiv{
  width: fit-content;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: #FFBB03;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  width: 30px;
  height: 30px;
  margin-top: 6px;
}
.userCommentDetailDiv{
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 8px;
  padding: 16px;
  /* background: #F1F1F1; */
  border: 1.5px solid #F1F1F1;
}
.notificationDialogMainDiv{
  background-color: #fff;
  border-radius: 8px;
  min-width: 600px;
  max-width: 600px;
  min-height: 550px;
  max-height: 550px;
  /* padding: 15px 30px 30px 15px; */
  padding: 15px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.notificationDialogMainDiv1{
  background-color: #fff;
  border-radius: 4px;
  min-width: 600px;
  max-width: 600px;
  min-height: auto;
  max-height: 550px;
  /* padding: 15px 30px 30px 15px; */
  /* padding: 15px; */
  /* overflow-x: hidden;
  overflow-y: scroll; */
}
.newDialogInnerDiv{
  padding: 24px 16px;
  font-family: 'ManRope' !important;
  overflow-x: hidden;
  overflow-y: scroll;
  min-height: auto;
  max-height: 460px;
}
.newDialogInnerDiv::-webkit-scrollbar {
  width: 4px;
}

.newDialogInnerDiv::-webkit-scrollbar:hover {
  width: 4px;
  background-color: #E4E8F4;
}


.newDialogInnerDiv::-webkit-scrollbar-thumb {
  background-color: #3068C8;
  border-radius: 5px;
  margin: 10px;
}
.newDialogInnerDiv::-webkit-scrollbar-thumb:hover {
  background-color: #7B7F88;
  border-radius: 5px;
  margin: 2px;;
}
.newDialogInnerDiv:hover::-webkit-scrollbar-corner {
  width: 4px;
  background-color: #E4E8F4;
  padding: 4;
}
.sharedUserListDiv::-webkit-scrollbar {
  width: 4px;
}

.sharedUserListDiv::-webkit-scrollbar:hover {
  width: 4px;
  background-color: #E4E8F4;
}


.sharedUserListDiv::-webkit-scrollbar-thumb {
  background-color: #3068C8;
  border-radius: 5px;
  margin: 10px;
}
.sharedUserListDiv::-webkit-scrollbar-thumb:hover {
  background-color: #7B7F88;
  border-radius: 5px;
  margin: 2px;;
}
.sharedUserListDiv:hover::-webkit-scrollbar-corner {
  width: 4px;
  background-color: #E4E8F4;
  padding: 4;
}
.notificationsItem{
display: flex;
padding: 12px;
justify-content: space-between;
align-items: center;
border-style: solid;
/* background: linear-gradient(white, white) padding-box, linear-gradient(9deg, rgba(255, 255, 255, 0.6) 58%, rgba(48, 104, 200, 1))14% border-box; */
background: linear-gradient(white, white) padding-box, linear-gradient(2.5deg, rgba(255, 255, 255, 0.6) 50%, rgba(48, 104, 200, 1))14% border-box;
border-radius: 8px;
border: 2px solid transparent;
border-width: 2px 2px 0px 0px !important;
margin-bottom: 8px;
}

.switchDialogMainDiv{
  background-color: #fff;
  border-radius: 4px;
  min-width: 450px;
  max-width: 450px;
  min-height: auto;
  max-height: 500px;
  /* padding: 20px 30px 15px 30px; */
  /* padding: 15px; */
  /* overflow-x: hidden; */
  /* overflow-y: scroll; */
}
.switchDialogMainDivNew{
  background-color: #fff;
  border-radius: 4px;
  min-width: 540px;
  max-width: 540px;
  min-height: auto;
  max-height: 500px;
  /* padding: 20px 30px 15px 30px; */
  /* padding: 15px; */
  overflow-x: hidden;
  /* overflow-y: scroll; */
}

.MuiSnackbar-root{
  z-index: 99 !important ;
}
.trashSnackBar > div{
  min-width: auto !important;
}
.InviteSnackBar1 > div{
  min-width: auto !important;
}
.InviteSnackBar1 {
z-index: 99999 !important;
}
.shareDialog{
  background-color: transparent !important;
}

.userListDiv{
  position: absolute;
  border-radius: 6px;
  padding: 10px;
  min-width: 560px;
  max-width: 560px;
  z-index: 999;
  background-color: white;
  margin: 5px 0px;
  box-shadow: 0 0 transparent, 0 0 transparent, 0 0 transparent, 0 0 transparent, 0px 5px 18px 0px rgba(0,0,0,0.2);
  max-height: 280px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.userListItem{
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 8px 10px;
  margin: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  cursor: pointer
}
/* .userListItem:hover{
  background-color:rgba(0, 0, 0,0.1);
} */
.InvitationsContactsitemDiv{
padding: 12px;
border-style: solid;
background: linear-gradient(white, white) padding-box, linear-gradient(4deg, rgba(255, 255, 255, 0.6) 55%, rgba(48, 104, 200, 1))14% border-box;
border-radius: 8px;
border: 2px solid transparent;
border-width: 2px 2px 0px 0px !important;
margin-bottom: 8px;
}
.emailChipDiv{
  width: fit-content;
  border-radius: 11px;
  background-color: rgba(25,25,25,0.05);
  color: rgba(25,25,25,1);
  font-size: 12px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 3px 8px 6px 8px;
  margin: 3px;
}
.shareDialogInviteBtn > button{
  font-size: 14px;
  font-weight: 600;
  margin-left: 8px;
  padding-top: 11px;
  padding-bottom: 11px;
  min-width: 125px;
  outline: none;
    background: #333;
    border: none;
    display: inline-block;
    border-radius: 6px;
    font-size: 12px;
    font-family: ManRopeBold;
}
.sharedUserListDiv{
  min-height: 280px;
  max-height: 280px;
  overflow-x: auto;
}
.pendingShareList{
  padding: 2px 10.5px;
  border-radius: 8px;
  /* color: orange;
  border: 1px solid orange; */
}
.rejectedShareList{
  padding: 2px 10.5px;
  border-radius: 8px;
  color: red;
  border: 1px solid red;
}
.exitedShareList{
  padding: 2px 10.5px;
  border-radius: 8px;
  color: red;
  border: 1px solid red;
}
.acceptedShareList{
  padding: 2px 6px;
  border-radius: 8px;
  /* color: green;
  border: 1px solid green; */
}
.revokeShareList{
  padding: 2px 6px;
  border-radius: 8px;
  /* color: #212529;
  border: 1px solid #212529; */
}
/* .revokeShareList:hover{
background-color: rgba(0, 0, 0, 0.1);
} */
.shareCountDiv{
  width: 12px;
    height: 13px;
    font-size: 9px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 3px;
    /* border: 1px solid black; */
    position: absolute;
    margin-top: 3px;
    margin-left: 19px;
    background: #fff;
    color: #3068C8;
    font-weight: bold;
}
.notificationListItem{
  padding: 10px 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  gap: 5px;
}
/* .notificationListItem:hover{
 background-color: rgba(0, 0, 0, 0.05);
 cursor: pointer;
} */
.markAllAsRead{
  display: flex;
    justify-content: flex-end;
    align-items: center;
}
.markAllAsRead > span{
  color: rgb(99, 120, 198);
  font-size: 15px;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 0.5px;
  /* opacity: 0.5; */
}
.notiUnreadCount{
  position: absolute;
  background: white;
  border-radius: 50%;
  margin-top: -30px;
  margin-left: 8px;
}
.notiUnreadCount > span{
  font-size: 10px;
  background: red;
  color: white;
  font-weight: 600;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;
}
.switchAccountActive{
  width: 32px;
  height: 32px;
  background-color: white;
  border-radius: 4px;
  /* box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px; */
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 8px 0px, rgba(0, 0, 0, 0.19) 0px 0px 0px 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.makeLinkLearn{
  color: #4772fa;
  text-decoration: none;
  cursor: pointer;
}
.makeLinkLearn:hover{
  text-decoration: underline;
}
.addNewListSelectContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.listSelectTypeDiv{
  width: 100%;
  padding: 15px 9px;
  border: 1px solid rgba(25, 25, 25, 0.2);
  border-radius: 5px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.listSelectTypeDiv:hover{
  border: 1px solid rgba(71,114,250,1);
}
.listSelectDownArrow{
  transform: rotate(90deg);
}
.selectListTypeDropDown{
  width: 305px;
  /* height: 100px; */
  background-color: white;
  position: absolute;
  margin-left: 0px;
  margin-top: 128px;
  box-shadow: 0 0 transparent, 0 0 transparent, 0 0 transparent, 0 0 transparent, 0px 5px 18px 0px rgba(0,0,0,0.2);
  border-radius: 6px;
  z-index: 99;
  padding: 5px;
}
.selectFolderTypeDropDown{
  width: 305px;
  min-height: auto;
  background-color: white;
  position: absolute;
  margin-left: 0px;
  margin-top: 200px;
  box-shadow: 0 0 transparent, 0 0 transparent, 0 0 transparent, 0 0 transparent, 0px 5px 18px 0px rgba(0,0,0,0.2);
  border-radius: 6px;
  z-index: 99;
  padding: 5px;
  max-height: 160px;
  overflow: scroll;
}
.listTypeDropElement{
  display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    font-size: 14px;
}
.listTypeDropElement:hover{
  background-color: rgba(71,114,250, 0.05);
}
.listTypeItemActive{
  color: #5E97C3 !important;
  display: flex;
  justify-content: space-between !important;
  align-items: center !important;
  width: 100% !important;
}
.addNewFloderMainDiv{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.addNewFloderInnerDiv{
  background-color: #fff;
  border-radius: 8px;
  min-width: 450px;
  max-width: 450px;
  min-height: auto;
  max-height: 500px;
  /* padding: 20px 30px 15px 30px; */
  /* padding: 15px; */
  overflow-x: hidden;
  box-shadow: 0 0 transparent, 0 0 transparent, 0 0 transparent, 0 0 transparent, 0px 5px 18px 0px rgba(0,0,0,0.2);
}
.addNewFloderContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0,0,0,0.09);
}
.noTaskDataDiv{
  width: 500px;
  padding: 10px;
  border: 2px solid white;
  font-size: 16px;
  font-weight: 600;
  color: white;
}


.listTooltip {
  /* position: relative;
  display: inline-block; */
  /* border-bottom: 1px dotted black; */
}

.listTooltip .listTooltiptext {
  visibility: hidden;
  min-width: 180px;
  max-width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 20px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 40%;
  margin-left: -60px;
  z-index: 999999999;
}

.listTooltip .listTooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.listTooltip:hover .listTooltiptext {
  visibility: visible;
}





/* .textOutLine{
  -webkit-text-stroke-width: 0.7px !important;
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.5) !important;
} */

.rstdi .delete{
  margin-top: 2px !important;
  border-radius: 4px !important;
}
.rstdi{
  height: auto !important;
  --rstdiHeight: auto !important;
  border-radius: 4px !important;
}

.rstdi .delete button{
  width: 100% !important;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-right: 10px !important;
}

/* "mySwipeDeleteDrag" : "mySwipeDelete" */
.mySwipeDeleteDrag{
  width: 278px !important;
}
.mySwipeDelete{
  width: auto !important;
}


.introContainer {
  padding: 10px 20px;
  position: relative;
  width: 300px;
  position: absolute;
  bottom: 80px;
}
.introContent {
  padding: 15px 20px;
      background-color: white;
      position: relative;
      border-radius: 6px;
}
.introLeft {
  left: 0;
}
/* Add arrows to the left container (pointing right) */
.introLeft::before {
  content: " ";
  height: 0;
  position: absolute;
  /* top: 184px; */
  width: 0;
  /* z-index: 1; */
  /* right: 0px; */
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
  bottom: -5px;
  transform: rotate(90deg);
  margin-left: 47px;
  /* /* margin-top: 19px; */
}

.introLeft1 {
  left: 150px;
}
/* Add arrows to the left container (pointing right) */
.introLeft1::before {
  content: " ";
  height: 0;
  position: absolute;
  /* top: 184px; */
  width: 0;
  /* z-index: 1; */
  /* right: 0px; */
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
  bottom: -5px;
  transform: rotate(90deg);
  margin-left: 47px;
  /* /* margin-top: 19px; */
}

.introLeft2 {
  left: 300px;
}
/* Add arrows to the left container (pointing right) */
.introLeft2::before {
  content: " ";
  height: 0;
  position: absolute;
  /* top: 184px; */
  width: 0;
  /* z-index: 1; */
  /* right: 0px; */
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
  bottom: -5px;
  transform: rotate(90deg);
  margin-left: 47px;
  /* /* margin-top: 19px; */
}
.introScreenDotDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.introScreenDotDiv>img{
width: 15px;
}

.introScreenBtn > button{
  font-size: 14px;
  font-weight: 600;
  padding-top: 7px;
  padding-bottom: 7px;
  outline: none;
  border: none;
  display: inline-block;
  border-radius: 5px;
  background: rgb(71, 114, 250);
  color: rgb(255, 255, 255);
  width: 100%;
  margin-top: 12px;
}
.introScreenSkip{
  position: absolute;
  right: 15px;
  top: 15px;
  color: darkgray;
  font-size: 15px;
  cursor: pointer;
}
.helpContactUsBtn{
  width: 350px;
  padding: 20px;
  border-radius: 8px;
  /* border: 1px solid black; */
  color: white;
  background-color: #4772fa;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}
.helpContactUsBtn:hover{
  background-color: #4772fa;
  opacity: 0.95;
}
.searchBarInputCss{
  outline: none !important;
}
.blinking {
  -webkit-animation: 1s blink ease infinite;
  -moz-animation: 1s blink ease infinite;
  -ms-animation: 1s blink ease infinite;
  -o-animation: 1s blink ease infinite;
  animation: 1s blink ease infinite;
  
}

@keyframes blink {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-ms-keyframes blink {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-o-keyframes blink {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.MuiMenuItem-root
{
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}

@media only screen and (max-width: 768px) {
  .newShareListItemDiv{
    margin: 4px 0px !important;
    padding: 6px 0px !important;
  }
  .userListDiv{
    position: absolute;
    border-radius: 6px;
    padding: 10px;
    min-width: 90%;
    max-width: 90%;
    z-index: 999;
    background-color: white;
    margin: 5px 0px;
    box-shadow: 0 0 transparent, 0 0 transparent, 0 0 transparent, 0 0 transparent, 0px 5px 18px 0px rgba(0,0,0,0.2);
    max-height: 280px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .newDialogInnerDiv{
    padding:16px 16px;
    font-family: 'ManRope' !important;
    overflow-x: hidden;
    overflow-y: scroll;
    min-height: auto;
    max-height: 560px;
  }
  .userListItem{
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 8px 10px;
    margin: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2px;
    cursor: pointer;
    flex-direction: column;
  }
  .invitationItemMiddleDiv{
    width: 50%;
    background:  rgba(48, 104, 200, 1);
    height: 1px;
    margin: 0 auto;
  }
  .InvitationsContactsitemDiv{
    padding: 12px 2px;
    border-style: solid;
    background: linear-gradient(white, white) padding-box, linear-gradient(16deg, rgba(255, 255, 255, 0.6) 62%, rgba(48, 104, 200, 1))14% border-box;
    border-radius: 8px;
    border: 2px solid transparent;
    border-width: 2px 2px 0px 0px !important;
    margin-bottom: 8px;
    }
  .InvitationsContactsitemDivDark{
    padding: 12px 2px;
    border-style: solid;
    background: linear-gradient(#223853, #223853) padding-box, linear-gradient(12.5deg, rgba(34, 56, 83, 0.6) 62%, rgba(48, 104, 200, 1))14% border-box;
    border-radius: 8px;
    border: 2px solid transparent;
    border-width: 2px 2px 0px 0px !important;
    margin-bottom: 8px;
    }
  .notificationsItem{
    display: flex;
    padding: 12px 8px 12px 0px;
    justify-content: space-between;
    align-items: center;
    border-style: solid;
    background: linear-gradient(white, white) padding-box, linear-gradient(12.5deg, rgba(255, 255, 255, 0.6) 62%, rgba(48, 104, 200, 1))14% border-box;
    border-radius: 8px;
    border: 2px solid transparent;
    border-width: 2px 2px 0px 0px !important;
    margin-bottom: 8px;
    }
  .notificationsItemDark{
    display: flex;
    padding: 12px 8px 12px 0px;
    justify-content: space-between;
    align-items: center;
    border-style: solid;
    background: linear-gradient(#223853, #223853) padding-box, linear-gradient(12.5deg, rgba(34, 56, 83, 0.6) 62%, rgba(48, 104, 200, 1))14% border-box;
    border-radius: 8px;
    border: 2px solid transparent;
    border-width: 2px 2px 0px 0px !important;
    margin-bottom: 8px;
    }
    

  .switchDialogMainDivNew{
    background-color: #fff;
    border-radius: 4px;
    min-width: 100%;
    max-width: 100%;
    min-height: auto;
    max-height: 500px;
    /* padding: 20px 30px 15px 30px; */
    /* padding: 15px; */
    overflow-x: hidden;
    /* overflow-y: scroll; */
  }
  .settingModalItems > img {
    height: 18px;
    width: 18px;
      }
      .settingModalItems > div > img {
    height: 18px;
    width: 18px;
      }
  .shareDiv{
    width: 32px;
    height: 32px;
  }
  .middlePaneTopDiv{
    background-color: #3068C8;
    padding: 16px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EAECF0;
    gap: 15px;
  }
  .newListAndGroup{
width: 47% !important;
  }
  .listTaskCountdiv{
    background: transparent;
  }
  .projectWithGroup{
      overflow: scroll;
  /* padding: 0px 10px; */
}
.addTaskSteps{
  margin-left: 0px;
}
.groupLists{
  margin-left: 0px;
}
.groupListsActive{
  margin-left: 0px;
  background: #FFFFFF66;
  /* border-left: 2px solid #FFFFFF; */
  border-right: none;
  border-radius: 0px 4px 4px 0px;
}
.addTaskStepsActive{
  border-right: none;
  margin-left: 0px;
  background-color: transparent ;
  font-size: 14px;
  font-weight: 600;
  gap: 18px;
  align-items: center;
  border-left: 2px solid #FFFFFF;
}

.addTaskSteps:hover{
  background-color: transparent !important;
}

  .userCommentDetailDiv{
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-radius: 8px;
    padding: 16px;
    /* background: #F1F1F1; */
    border: 1.5px solid #F1F1F1;
  }

  .textAreaInputForTaskNote{
    font-size: 19px;
  }
  .commentArea{
    font-size: 17px;
  }

  .textOutLine{
    /* text-shadow: 0px 0px 3px #000, 0px 0px 2px #000; */
  
    /* -webkit-text-stroke-width: 0.7px !important;
    -webkit-text-stroke-color: rgba(0, 0, 0, 0.5) !important; */
  color: #000 !important;
  
    /* text-shadow: -1px 1px 1px #000, 1px 2px 0 #000, 1px -1px 0 #000, -1px -1px 0 #000 */
  }
  .auto-grow-input:focus-within {
    outline: none !important;
    /* background: #000 !important; */
  }
  .addNewListSelectContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  .shareDialogInputAndIcon{
    display: flex;
    justify-content: center;
    border: 1px solid rgba(16, 24, 40, 0.2);
    margin-top: 0px;
    border-radius: 4px;
  }
  .listSelectTypeDiv{
    width: 100%;
    padding: 14px 9px;
    border: 1px solid rgba(25, 25, 25, 0.2);
    border-radius: 5px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

 html {
  position: fixed;
  height: 100%;
  overflow: hidden;
  overscroll-behavior: none;
  background-color: #000 !important;  
}

body {
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  background-color: #000 !important; 
} 
  .titleHeadMenu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* width: 100%; */
    /* margin: 0px 10px; */
}
.MainUpperDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-top: 5px; */
  padding: 24px 16px;
}
#header {
  background-color: #f1f1f1; /* Grey background */
  padding: 50px 10px; /* Some padding */
  color: black;
  text-align: center; /* Centered text */
  font-size: 90px; /* Big font size */
  font-weight: bold;
  position: fixed; /* Fixed position - sit on top of the page */
  top: 0;
  width: 100%; /* Full width */
  transition: 0.2s; /* Add a transition effect (when scrolling - and font size is decreased) */
}
.MainUpperRightDiv {
  display: flex;
  gap: 10px;
  font-size: 16px;
  font-weight: 500;
  margin-right: 0px;
}
.shareCountDiv {
  width: 13px;
    height: 13px;
    font-size: 8px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 2px 5px; */
    border: 1px solid black;
    position: absolute;
    margin-top: -4px;
    margin-left: 15px;
    background: white;
    color: black;
    font-weight: bold;
}
.sideTaskBarHeadName{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  font-size: 18px;
  padding: 0px 12px 12px 10px;
  margin-bottom: 5px;
  width: fit-content;
}
.react-confirm-alert {
  width: 90%;
}
/* custom alert dialog styles */
.add-dialog{
  width: 100%;
/* padding: 18px; */
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
margin: 10px !important;
  }
  .switchDialogMainDiv{
    text-align: center;
    width: 100%;
    padding: 25px 15px 40px 15px;
    min-width: 100%;
    max-width: 100%;
    max-height: 600px;
    overflow: auto;
  }
.notificationDialogMainDiv{
  min-width: 100%;
  max-width: 100%;
  max-height: 300px;
  overflow: auto;
  padding: 10px;
  padding-bottom: 40px;
  border-radius: 0px;
  margin-top: -1px;
}
.notificationDialogMainDiv1{
  min-width: 100%;
  max-width: 100%;
  max-height: 600px;
  overflow: auto;
  padding: 10px;
  padding-bottom: 40px;
  border-radius: 0px;
  margin-top: -1px;
}
.addNewFloderInnerDiv{
  min-width: 94%;
  max-width: 94%;
  /* padding: 15px ; */
  margin: 10px;
}
.MainProfileDiv{
  display: flex;
  align-items: center;
  gap: 10px;
  /* padding-top: 13px; */
  /* padding-bottom: 6px; */
  padding-right: 12px;
  padding-left: 12px;
  border-bottom: none;
  margin-bottom: 5px;
}
.drawerHeading{
  padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #3068C8;
    color: white;
    /* border-bottom: 1px solid lightgray; */
}
.drawerHeadingDone{
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3068C8;
  color: white;
  font-weight: 600;
  font-size: 15px;
}
.mainDrawerContainer > div{
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  /* background: rgba(0, 0, 0, 0.5); */
}
.css-9emuhu-MuiPaper-root-MuiDrawer-paper
{
  background-color:transparent !important;
}
.drawerIcon{
  display: flex;
    justify-content: center;
    padding-top: 10px;
    background: #3068C8;
    margin-bottom: -1px;
}
.drawerIcon > div{
  width: 35px;
  /* border: 2px solid gray; */
  border: 2px solid white;
  border-radius: 20px;
}
.addTaskInput::placeholder {
  color: #fff;
}
.addTaskInputDark::placeholder {
  color: #fff;
}

.addTaskInput:focus::placeholder {
  color: #fff;
}
.addTaskInputDark:focus::placeholder {
  color: #fff;
}
.taskListDiv{
  margin-top: 15px;
  height: calc(100vh - 10px);
  overflow: scroll;
  padding: 0px 12px 10px 16px;
}
.taskStepTitleRow{
  /* font-size: 18.36px; */
  font-size: 20px;
  padding: 6px 6px;
  padding-right: 6px;
  /* font-family: 'Helvetica' !important ; */
  font-family: 'ManRope' !important ;
  font-weight: 500;
  color: #fff;
  gap:22px
}
.groupTitleRow{
  /* font-size: 18.8px; */
  font-size: 20.5px;
  font-family: 'ManRopeBold' !important ;
  font-weight: 500;
}
.listTaskCountdiv > div > span{
  font-size: 14px;
  /* background: rgba(0, 0, 0, 0.1); */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px 5px;
  color: #fff;
  background: #D9D9D952;
  font-family: 'HelveticaNarrowBold' !important ;
  font-weight: 400;
}
.listTaskCountdiv > span{
  font-size: 14px;
  /* background: rgba(0, 0, 0, 0.1); */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px 5px;
  color: #fff;
  background: #D9D9D952;
  font-family: 'HelveticaNarrowBold' !important ;
  font-weight: 400;
}
.rightListDiv > ul{
  box-sizing: border-box;
  padding: 5px 0px 40px 0px;
  margin: 0;
  list-style: none;
}
.rightListDiv > ul > li {
  padding: 10px 14px;
  display: flex;
  align-items: center;
  gap: 14px;
  font-size: 18px;
  font-weight: 400;
}
.settingModalItems {
  padding: 8px 15px 8px 20px;
  display: flex;
  align-items: center;
  gap: 18px;
  font-size: 18px !important;
  cursor: pointer;
  width: 100%;
}
.switchAccountActive{
  width: 25px;
  height: 25px;
}
.threeDots {
  opacity: 1;
}
.searchBarInputCss{
  outline: none !important;
}
.searchBarInputCss:focus{
  outline: none;
}
.groupLists:hover{
  background-color: unset ;
}
.newListAndGroup:hover{
  background-color: #ffffff4d;
   }
   /* .css-9emuhu-MuiPaper-root-MuiDrawer-paper
   {
    background-color: unset !important;
   } */
   .selectFolderTypeDropDown{
    width: 260px;
    min-height: auto;
    background-color: white;
    position: absolute;
    /* margin-left: 78px; */
    margin-top: 200px;
    box-shadow: 0 0 transparent, 0 0 transparent, 0 0 transparent, 0 0 transparent, 0px 5px 18px 0px rgba(0,0,0,0.2);
    border-radius: 6px;
    z-index: 99;
    padding: 5px;
    max-height: 160px;
    overflow: scroll;
  }
  .selectListTypeDropDown{
    width: 260px;
    /* height: 100px; */
    background-color: white;
    position: absolute;
    /* margin-left: 78px; */
    margin-top: 128px;
    box-shadow: 0 0 transparent, 0 0 transparent, 0 0 transparent, 0 0 transparent, 0px 5px 18px 0px rgba(0,0,0,0.2);
    border-radius: 6px;
    z-index: 99;
    padding: 5px;
  }
  .shareDialogMainDiv{
    padding: 10px;
    min-width: 100%;
    max-width: 100%;
    background-color: white;
  }
  .sidebarFirstDiv{
    /* height: 100vh; */
    /* height: calc(100vh - 30px); */
    flex: 1;
    /* padding: 12px; */
    overflow: scroll;
}
.divOverLapNotes{
  position: absolute;
  min-height:400px;
width: 100%;
z-index: 1;
background: rgb(4, 3, 75);
}
.textAreaInputForTaskNote{
  min-height: 150px ;
  max-height: 150px ;
  -webkit-user-select: text;
  user-select: text;
}
.editTextTaskName{
  /* font-size: 16.5px; */
  font-size: 16px;
  /* font-weight: 800; */
}
.editTextTaskHeadingName{
  /* font-size: 16px; */
  font-size: 18px;
  font-weight: 800;
}
.taskTitleHead {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  font-size: 20px;
  font-weight: bold;
  word-break: break-word;
}

.slide-right {
  animation: .3s slide-right;
  position: absolute;
}
@keyframes slide-right {
  from {
    right: -100%;
  }
  to {
    right: 0%;
  }
}
.slide-left {
  animation: .1s slide-left;
  position: absolute;
}
@keyframes slide-left {
  from {
    right: 100%;
  }
  to {
    right: 0%;
  }

}
.moveToLeft {
  -webkit-animation: moveToLeft .3s ease both;
  animation: moveToLeft .3s ease both;
  position: absolute;
}

.moveToRight {
  -webkit-animation: moveToRight .3s ease both;
  animation: moveToRight .3s ease both;
  position: absolute;
}

@-webkit-keyframes moveToLeft {
  from { left:0%}
  to { left:-100% }
}
@keyframes moveToLeft {
  from { left:0%}
  to { left:-100% }
}



@-webkit-keyframes moveToRight { 
  from { left:0%}
  to { left:100% }
}
@keyframes moveToRight { 
  from { left:0%}
  to { left:100% }
}






[data-placeholder]:empty:before{
  content: attr(data-placeholder);
  color: #888;
  font-size: 16px;
  font-weight: 500;
}
.custom-file-input::before {
  content: '+ Add attachments';
  /* color: #000; */
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  /* border: 1px solid #999; */
  border-radius: 3px;
  /* padding: 5px 8px; */
  outline: none;
  white-space: nowrap;
  /* -webkit-user-select: none; */
  cursor: pointer;
  /* text-shadow: 1px 1px #fff; */
  font-weight: 500;
  font-size: 17.5px;
}
.custom-file-input-dark::before {
  content: '+ Add attachments';
  /* color: #fff; */
  /* color:'#969696'; */
  display: inline-block;
  /* background: linear-gradient(top, #f9f9f9, #e3e3e3); */
  /* border: 1px solid #999; */
  border-radius: 3px;
  /* padding: 5px 8px; */
  outline: none;
  white-space: nowrap;
  /* -webkit-user-select: none; */
  cursor: pointer;
  /* text-shadow: 1px 1px #fff; */
  font-weight: 500;
  font-size: 17.5px;
}
.addSubTaskInput{
  font-size: 17.5px;
  margin-left: 0px;
  margin-top: 0px;
}
.addSubTaskInput::placeholder {
  color: #605E5C;
}
.sidebarFirstDiv::-webkit-scrollbar{
  display: none;
}
/* .taskLine{
  opacity: 0.75;
  color: #cccccc !important;
} */
.flieName{
  /* opacity: 0.45; */
  /* color: #bdbdbd !important; */
  font-size: 13px;
}
.helpContactUsBtn {
  width: 250px;
  padding: 12px;
  border-radius: 8px;
  /* border: 1px solid black; */
  color: white;
  background-color: #4772fa;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}
}



.mainFolderDiv{
  height: 40px;
  background-color: #fff;
  border-radius: 4px;
  margin: 10px 12px 10px 20px;
  padding: 0px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.rightPaneCardItems{
  border-radius: 8px;
background:  #FFF;
box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.05);
height: 48px;
padding: 14px;
display: flex;
justify-content: flex-start;
align-items: center;
gap: 12px;
margin-bottom: 16px;
}
.mic{
  background: #000;
    width: 50px;
    height: 50px;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 50px;
    margin-left: 10px;
}
.quePara
{
width: 70%;
text-align: center;
margin-top: 20px;
background: blue;
padding: 15px 15px;
border-radius: 10px;
color: #FFF;
font-family: 'ManRopeBold';
}
.queDiv
{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.stopButton{
  font-size: 14px;
  background: blue;
  padding: 10px 18px;
  color: #fff;
  font-family: 'MANROPEBOLD';
  border-radius: 20px;
  margin-left: 15px;
}
.notifyInnerBtns{
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #dbd3d3;
  border: 1px solid #2F69C8;
  width: 150px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 30px;
  font-size: 18px;
  color: #000;
  font-family: 'ManRopeSemiBold';
  cursor: pointer;
}
.notifyInnerBtnsActive{
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #2F69C8;
  border: 1px solid #dbd3d3;
  width: 150px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 30px;
  font-size: 18px;
  color: #fff;
  font-family: 'ManRopeSemiBold';
  cursor: pointer;
}
/* input[type="date"]:after {
  content: "\25BC"; 
  color: #555;
  padding: 0 5px;
} */
