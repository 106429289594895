.inviteMainContainer{
}
.inviteMainInnerContainer{
    width: 18.75rem;
    margin: 0px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 80px;
}
.joinBtn{
    background-color: #4772fa;
    color: #fff;
    font-size: 1rem;
    padding: 0.75rem 0;
    border: none;
    outline: 0;
    width: 17.1875rem;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    margin-top: 25px;
}
.joinBtn:hover{
    background-color: #6c8efb;
}
.inviteAvatar{
    width: 5.625rem;
    height: 5.625rem;
    border-radius: 50%;
    margin-bottom: 50px;
}