.helpSupportMainContainerDiv{
    width:100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh;
    margin-right: 3px;
    background: #fff;
}
.helpSupportMainDiv{
    width:100%;
    padding:40px 24px 40px 16px;
}

.helpSupportMainContainerDiv::-webkit-scrollbar {
    width: 4px;
  }
  
  .helpSupportMainContainerDiv::-webkit-scrollbar-thumb {
    background-color: #3068C8;
    border-radius: 5px;
    margin: 10px;
  }
  .helpSupportMainContainerDiv:hover::-webkit-scrollbar-corner {
    width: 8px;
    background-color: #E4E8F4;
    padding: 4;
  }

.faqItemDiv{
    box-shadow: 0px 4px 8px 0px #00000014;
    background: #FFFFFF;
    border-radius: 12px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    margin-bottom: 20px;
}
.faqItemInnerDiv{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
}
.faqAddIconDiv{
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #E9ECF4;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.faqAddIconDiv:hover{
    background: #dddee2;
}
.faqCrossIconDiv{
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #3068C8;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.faqCrossIconDiv:hover{
    background: rgba(48, 104, 200, 0.8)
}
.confussionMainDiv{
    background: #3068C8;
    width: 97%;
    margin: 0 auto;
    border-radius: 4px;
    height: 525px;
    margin-bottom: 30px;
}
.confussionBgImgDiv{
background-image: url('../../assets/faqBgDesign.png');
background-repeat: no-repeat;
background-position: right;
height: 525px;
width: 100%;
padding: 38px 110px;
display: flex;
justify-content: flex-start;
align-items: flex-start;
}
.confussionBgImgDiv>div{
width: 50%;
height: 100%;
}
.sendSupportMsgDiv{
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 4px 15px 0px #0000001A;
    padding: 40px;
}
.supportInfoDiv{
    display:flex;
    justify-content:flex-start;
    align-items:center;
    gap:16px;
    margin-bottom: 26px;
}
.supportInfoDiv > div > p{
font-size: 16px;
font-weight: 500;
font-family: 'ManRope';
color: #fff;
margin-bottom: 2px;
}
.socialIconDiv{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}
.supportSendMsgInput{
    border: none;
    border-bottom: 1px solid #828B94 !important;
    color: #172D46;
    padding: 8px 0px;
    width: 100%;
    /* margin-top: 20px; */
}
.supportSendMsgInput:focus{
outline: none;
}
.supportSendBtn{
    background: #3068C8;
    color: #fff;
    border-radius: 48px;
    /* padding: 24px 120px 24px 120px; */
    width: 100%;
    margin-top: 60px;
    font-size: 18px;
    font-weight: 500;
    font-family: "ManRopeSemiBold";
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.supportSendBtn:hover{
    background: #5080d3;
}
.sendMsghead{
    font-size: 20px;
    font-weight: 600;
    font-family: "ManRopeSemiBold";
    text-transform: capitalize;
}

@media only screen and (max-width: 768px) {
    .helpSupportMainDiv{
        padding:24px 16px;
    }
    .faqAddIconDiv{
        width: 20px;
        height: 20px;
    }
    .faqCrossIconDiv{
        width: 20px;
        height: 20px;
    }
    .faqAddIconDiv>img{
        width: 8px !important;
        height: 8px !important;
    }
    .faqCrossIconDiv>img{
        width: 8px !important;
        height: 8px !important;
    }
    .faqItemInnerDiv{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
        width: 100%;
    }
    .faqItemDiv>div{
        gap: 10px;
    }
    .confussionMainDiv{
        background: #3068C8;
        width: 100%;
        border-radius: 0px;
        height: auto;
        margin-top: 0px !important;
        margin: 0px !important;
    }
    .confussionBgImgDiv{
    background-image: url('../../assets/faqBgDesign.png');
    background-repeat: no-repeat;
    background-position: bottom;
    height: auto;
    width: 100%;
    padding: 24px 16px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 24px;
    }
    .confussionBgImgDiv>div{
        width: 100%;
        height: 100%;
        }
        .sendSupportMsgDiv{
            background: #fff;
            border-radius: 16px;
            box-shadow: 0px 4px 15px 0px #0000001A;
            padding: 24px;
        }
        .supportSendBtn{
            border-radius: 6px;
        }
        .sendMsghead{
            font-size: 20px;
            font-weight: 600;
            font-family: "ManRopeSemiBold";
        }
        .socialIconDiv > img{
       width: 30px !important;
        }
        .socialIconDiv{
       margin-top:24px !important;
        }
        .supportInfoDiv > img{
            width: 30px !important;
            height: 30px !important;
        }
        .supportInfoDiv{
          gap: 10px !important;
          margin-bottom: 10px;
        }
}