.container-main{
    max-width: 60%;
    min-width: 60%;
    /* margin: 50px auto;
    color: rgba(0,0,0,.85); */

    margin: 0px auto;
    color: rgba(0,0,0,.85);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.container-main-switch{
    max-width: 400px;
    min-width: 300px;
    /* margin: 50px auto; */
    color: rgba(0,0,0,.85); 
    margin-top: -26px;
}
.auth-container-box{
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(0,0,0,.06);
    border-radius: 40px;
    /* padding: 40px 50px 32px; */
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    /* display: inline-block; */
    width: 100%;
    align-items: center;

}
.blue-box
{
    display: flex;
    flex-direction: row;
    justify-content: center;
    bottom: 5.5%;
    position: absolute;
    margin-left: -5%;
}
.auth-container-box-switch{
    /* background-color: rgba(255, 255, 255, 1); */
    /* border: 1px solid rgba(0,0,0,.06); */
    /* border-radius: 40px; */
    /* padding: 20px 35px 20px;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1); */
}
@keyframes color-change {
    0% {
       border-bottom-color: rgba(0,0,0,.06);
    }
    100% {
       border-bottom-color: #4772fa;
    }
}
.inputContainerDiv{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 5px;
    /* border-bottom: 1px solid rgba(0,0,0,.06); */
    border: 1px solid rgba(208, 213, 221, 1);
    border-radius: 8px;
    /* transition: border-color .15s ease-in-out; */
}
.inputContainerDivActive{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 5px;
    /* border-bottom: 1px solid #4772fa; */
    border: 1px solid #4772fa;
    border-radius: 8px;
    /* border-radius: 1px; */
    transition: border-color 0.25s ease-in-out;
}
.inputFeild{
    width: 100%;
    border: none;
    padding: 10px 10px 8px 8px;
    background: transparent;
}
.inputFeild::placeholder {
color: #D1D1D1 !important;
}
.inputFeild:focus{
outline: none;
background: transparent;
}
.authBtn{
    box-shadow: none;
    border-radius: 10px;
    border-style: solid;
    border-width: 0;
    min-width: 100px;
    cursor: pointer;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    outline: 0;
    display: inline-block;
    font-size: 18px;
    font-family: 'ManRopeSemiBold';
    color: #fff;
    /* background-color: #4772fa; */
    background: rgba(48, 104, 200, 1);
    transition: background-color .2s ease;
    padding: 12px 24px;
    width: 100%;
    margin: 0 0 12px;
    margin-top: 10px;
}
.authBtnLoader{
    box-shadow: none;
    border-radius: 10px;
    border-style: solid;
    border-width: 0;
    min-width: 100px;
    cursor: pointer;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    outline: 0;
    display: inline-block;
    font-size: 18px;
    font-family: 'ManRopeSemiBold';
    color: #fff;
    /* background-color: #6989f1; */
    background: rgba(48, 104, 200, 0.8);
    transition: background-color .2s ease;
    padding: 12px 24px;
    width: 100%;
    margin: 0 0 12px;
    margin-top: 10px;
}
.authBtn:hover{
    /* background-color: #6c8efb */
    background: rgba(48, 104, 200, 0.8);
}
.IAgreeLine{
    font-size: 12px;
    line-height: 16px;
    color: rgba(0,0,0,.36);
}

.dividerLine {
    color: rgba(0,0,0,.24);
    font-size: 13px;
    padding: 30px 0;
    overflow: hidden;
    text-align: center;
}
.dividerLine:before {
    background-color: rgba(0,0,0,.06);
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
    right: 0.5em;
    margin-left: -50%;
} 
.dividerLine:after {
    background-color: rgba(0,0,0,.06);
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
    left: 0.5em;
    margin-right: -50%;
}
.socialLoginBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 1px solid #f0f0f0;
    border-radius: 20px;
    padding: 7px 9px 9px 9px;
    box-sizing: border-box;
    color: rgba(0,0,0,.54);
}
.socialLoginBtn:hover{
    background-color: #eee;
}
.socialLoginDiv{
    gap: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.errorMsgLine{
    display: flex;
    justify-content: flex-end;
    padding: 5px 0px;
}
.makeLink{
    color: #4772fa;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
}
.makeLink:hover{
    text-decoration: underline;
}
.sentEmailMsg{
    line-height: 25px;
    padding: 40px 0;
    text-align: center;
    font-size: 14px;
}
.loginTopSwicthView{
    display: flex;
    padding: 50px;
    position: absolute;
    top: 0px;
    right: 0;
}
.loginTopSwicthView > div{
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.06);
    border-radius: 2px;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    gap: 12px;
    cursor: pointer;
}
.loginTopSwicthView > div:hover{
    background-color: rgba(0,0,0,.04);
}



@media only screen and (max-width: 768px) { 
    .container-main {
        max-width: 100%;
        min-width: 100%;
        margin: 0px auto;
        color: rgba(0,0,0,.85);
        width: 100%;

    margin: 0px auto;
    color: rgba(0,0,0,.85);
    height: 100vh;
    display: unset;
    }
    
    .auth-container-box{
    background-color: rgba(255, 255, 255, 1);
    border: none;
    border-radius: 40px;
    /* padding: 40px 50px 32px; */
    box-shadow: none;
    display: flex;
    /* display: inline-block; */
    width: 100%;
    padding : 0 5%;
    align-items: center;

}
}