.manageAccountContainer{
    text-align: left;
    /* margin-top: -28px; */
}
.manageAccountInput{
    padding-left: 12px;
    padding-right: 12px;
    font-size: 14px;
    border: 1px solid rgba(25,25,25,0.2);
    border-radius: 4px;
    height: 36px;
    outline: none;
}
.manageAccountInput:focus{
   border-color: rgba(71,114,250,1);
   outline: none;
}
.manageAccountInnerContentDiv{
    margin-top: 6px;
    display: flex;
    justify-content: center;
    gap: 8px;
    flex-direction: column;
    min-width: 270px;
    max-width: 270px;
}
.manageBtnContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}
.manageBtnContainer > div{
    border: 1px solid rgba(48,104,200, 1);
    font-size: 14px;
    text-align: center;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 4px;
    width: 100%;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3068C8;
}
.manageCancleBtn:hover{
    cursor: pointer;
background-color: rgba(0, 0, 0, 0.04);
}
.manageSaveBtn{
background-color: rgba(48,104,200, 0.5);
border: 1px solid rgba(48,104,200,0.5) !important;
color: #fff !important;
cursor: pointer;
}
.manageSaveBtnActive{
background-color: #3068C8;
border: 1px solid #3068C8 !important;
color: #fff !important;
cursor: pointer;
}
.sendCodeBtn{
    color: rgba(25,25,25,0.4);
    width: 140px;
    background-color: rgb(238 238 238/1);
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid rgba(25,25,25,0.2);
    cursor: default;
}
.sendCodeBtnActive{
    color: white;
    width: 140px;
    background-color: #3068C8;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid #3068C8;
    cursor: pointer;
}
.react-confirm-alert > div{
    z-index: 9999 !important;
}
.unCheckDiv{
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 1px solid rgba(25,25,25,0.5);
    margin-top: 2px;
}
.checkIconDiv{
    width: 16px;
    height: 16px;
    border-radius: 4px;
    margin-top: 2px;
}
.pointCheckBoxDiv{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    gap: 10px;
    font-size: 16px;
    margin-top: 22px;
    cursor: default;
}
.manageAccountInputDark{
    padding-left: 12px;
    padding-right: 12px;
    font-size: 14px;
    border: 1px solid rgba(25,25,25,0.2);
    border-radius: 4px;
    height: 36px;
    outline: none;
    background-color: black;
    color: #fff;
}
.manageAccountInputDark:focus{
   border-color: rgba(71,114,250,1);
   outline: none;
}