.mainCookieDiv {
  font-size: 12px;
  color: rgb(48, 48, 48);
  background: rgb(255, 255, 255);
  left: 0;
  bottom: 0;
  width: 45.7em;
  display: block;
  border-radius: 5px;
  box-sizing: border-box;
  opacity: 1;
  position: fixed;
  z-index: 9999;
  border-width: 0;
  margin: 1.667em;
  box-shadow: 0 0 35px 0 rgba(0,0,0,.25);
  padding: 2.5em 2.86em;
  }
  .cookieContent {
    max-width: 122.5em;
    margin: auto;
  }
  .accept {
    background: rgb(0, 58, 250);
    border-color: rgb(0, 58, 250);
    font-size: 12px;
    cursor: pointer;
    width: 12.86em;
    min-width: 12.86em;
    max-width: 16em;
    border-width: 1px;
    border-radius: 3px;
    border-style: solid;
    color: #fff;
    padding: 0.625em 2em;
    text-align: center;
    font-weight: 600;
  }
  .decline {
    background: transparent;
    border: 2px solid rgb(71, 114, 250);
    border-radius: 12px;
    padding: 10px 30px;
    cursor: pointer;
  }
  .contentDiv {
    margin-bottom: 1.5em;
    text-align: center;
  }
  .Btn_div {
    /* width: 20%; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  .mobileCookieDiv{
    width: 100%;
    /* border: 1px solid red; */
    position: absolute;
    bottom: 0;
    z-index: 999;
    padding: 12px;
    left: 0;
  }
  @media screen and (max-width: 768px) {
    .cookieContent {
      display: flex;
      flex-direction: column;
      padding: 20px 0px;
    }
    .Btn_div {
      display: flex;
      flex-direction: column;
    }
    .mainCookieDiv {
      width: 100% !important;
      font-size: 12px;
      color: rgb(48, 48, 48);
      background: rgb(255, 255, 255);
      left: 0;
      bottom: 0;
      width: 45.7em;
      display: block;
      border-radius: 5px;
      box-sizing: border-box;
      opacity: 1;
      position: unset;
      z-index: 9999;
      border-width: 0;
      margin: 0;
      box-shadow: 0 0 35px 0 rgba(0,0,0,.25);
      padding: .5em .86em;
      }
  }
  